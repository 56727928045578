<template>
  <div>
    <!-- 大屏首页 -->
    <section class="section p0" style="padding-bottom: 0">
      <div class="col-lg-12 p0 container_max">
        <img
          src="@/assets/images/houseInfo/houseInfo_bj.png"
          style="width: 100%; height: 100%; object-fit: fill"
          alt=""
        />
        <div class="center_box flex-cc">
          <h1 class="T64px_Bold colorfff">CONG.FU</h1>
          <span class="T20px_Regular colorfff text_personal"
            >Personal Real Estate Corporation</span
          >
          <span class="T20px_Regular colorfff zh_text_personal"
            >傅聪个人房地产公司</span
          >
        </div>
      </div>
    </section>
    <!-- 详情信息 -->
    <section class="section about_my">
      <div class="container flex-w">
        <div class="col-lg-4 flex-c">
          <h1 class="T48px_Regular color000 about_my_title flex-cc">
            房源信息
          </h1>
          <div class="label_box colorfff flex-wc">
            <span class="T20px_Regular">${{ numberFormat(info.price) }}</span>
            <span class="xian"></span>
            <span class="T20px_Regular">月</span>
          </div>
          <span class="T24px_Regular color333"> {{ info.name }} </span>
          <template v-for="item in locationList">
            <span
              class="T18px_Regular color828282"
              v-if="item.value == info.region"
              :key="item.value"
              >{{ item.label }}</span
            >
          </template>

          <ul class="color000 tips_box T16px_Regular">
            <li class="li">{{ info.room }} 房间</li>
            <li class="li">{{ info.toilet }} 厕所</li>
            <li class="li">{{ info.size }} 平方英尺</li>
            <li class="li">{{ info.life }}年</li>
            <li class="li">{{ info.storage_space }} 储物空间</li>
            <li class="li">{{ info.parking }} 车位</li>
          </ul>

          <button class="y_info_btn T14px_Regular animation_middle_lfetRig">
            预约看房
          </button>
          <button v-show="info.file.length" @click="fileBtn(info.file[0])" class="y_info_btn T14px_Regular animation_middle_lfetRig">
            下载房屋申请表
          </button>
        </div>
        <div class="col-lg-8 flex-wr h100">
          <div class="right_logo_box">
            <el-carousel
              class="right_logo"
              ref="cardShow"
              :autoplay="false"
              arrow="never"
              indicator-position="none"
            >
              <el-carousel-item v-for="item in info.photo" :key="item">
                <!-- <img class="right_logo" :src="item" alt="" /> -->
                <el-image
                  class="right_logo"
                  ref="previewImg"
                  :src="item"
                  :preview-src-list="info.photo"
                >
                </el-image>
              </el-carousel-item>
            </el-carousel>

            <button
              @click="viewBigPicture"
              class="Preview_box flex-wc animation_right"
            >
              <img
                class="icon"
                src="../../assets/images/houseInfo/img.png"
                alt=""
              />
              <span class="T14px_Regular colorfff">图片预览</span>
            </button>

            <div class="qie_box flex-w">
              <button
                @click="arrowClick()"
                class="qie_btn animation_middle_lfetRig"
              >
                <img
                  class="icon"
                  src="../../assets/images/houseInfo/arr_l.png"
                  alt=""
                />
              </button>
              <button
                @click="arrowClick('right')"
                class="qie_btn animation_middle_lfetRig"
              >
                <img
                  class="icon"
                  src="../../assets/images/houseInfo/arr_r.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 介绍 -->
    <section class="section introduce">
      <div class="container flex-w">
        <div class="col-lg-7 flex-c">
          <span class="T12px_Regular colorA6B565">优质房源</span>
          <span class="T24px_Regular phone">1336-5222 Rgwood Street</span>

          <span class="T16px_Regular remarks_text">
            {{ info.introduce }}
          </span>

          <div class="contacts_box flex-wb">
            <div class="left_box flex-w">
              <img
                class="user_logo"
                src="@/assets/images/houseInfo/contacts_logo.png"
                alt=""
              />
              <div class="flex-c">
                <span class="T12px_Regular color828282">大温房产经纪</span>
                <span class="T16px_Regular color333">傅聪</span>
                <span class="T16px_Regular one">RE/MAX CREST REALTY</span>
                <span class="T16px_Regular one color000">
                  (1) 604-700-3882 alex.congfu@gmail.com
                </span>
              </div>
            </div>
            <img
              class="contacts_img"
              src="@/assets/images/houseInfo/contacts_img.png"
              alt=""
            />
          </div>

          <div class="ideal_box flex-cc">
            <span class="T24px_Regular ideal_name">梦居之旅</span>
            <span class="T16px_Regular ideal_tips"
              >让我们为您的工作，留学之旅保驾护航</span
            >
            <button class="T14px_Regular ideal_btn colorfff">
              了解更多详情
            </button>
          </div>
        </div>
        <div class="col-lg-5 flex-wr">
          <div class="max_ask_box">
            <div class="flex-c ask_box" v-if="info.convenientArr.length">
              <span class="T24px_Regular ask_name">设施便利</span>
              <div
                class="ask_item flex-w"
                v-for="item in info.convenientArr"
                :key="item"
              >
                <img
                  class="dui_img"
                  src="@/assets/images/houseInfo/dui.png"
                  alt=""
                />
                <span class="T12px_Regular ask_item_name">{{
                  stringName(item, "convenientFacilitiesList")
                }}</span>
              </div>
            </div>
            <div class="flex-c ask_box" v-if="info.tenantArr.length">
              <span class="T24px_Regular ask_name">租客条件</span>
              <div
                class="ask_item flex-w"
                v-for="item in info.tenantArr"
                :key="item"
              >
                <img
                  class="dui_img"
                  src="@/assets/images/houseInfo/dui.png"
                  alt=""
                />
                <span class="T12px_Regular ask_item_name">{{
                  stringName(item, "tenantConditionsList")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 全部房子 -->
    <section class="section all_house">
      <div class="container flex-w">
        <div class="house_info_list">
          <header class="color828282 flex-c header_box">
            <span class="T24px_Regular header_title">相同区域</span>
            <span class="T12px_Regular">您或许会对以下感兴趣</span>
          </header>

          <div class="house_list flex-w">
            <div
              class="house_list_item"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="img_box">
                <img class="house_logo" :src="item.photo[0]" alt="" />
                <button
                  @click="infoRouter(item.id)"
                  class="T14px_Regular no_show_btn colorfff"
                >
                  查看详情
                </button>
                <div class="label_box colorfff flex-wc">
                  <span class="money T14px_Regular">${{numberFormat(info.price)}}</span>
                  <span class="xian"></span>
                  <span class="unit T14px_Regular">月</span>
                </div>
              </div>
              <div class="flex-c info_box">
                <span class="T12px_Regular colorA6B565">优质房源</span>
                <span class="T24px_Regular colorF2F2F2 name">{{
                  item.name
                }}</span>
                <span class="T16px_Regular colorE0E0E0 area"
                  >{{ item.size }} sqft/总面积</span
                >
              </div>
              <div class="flex-w footer_box">
                <div class="col-lg-6 footer_info_box flex-w">
                  <img
                    class="info_icon"
                    src="@/assets/images/houseInfo/brds.png"
                    alt=""
                  />
                  <span class="T16px_Regular colorE0E0E0"
                    >{{ item.parking }} Bed</span
                  >
                </div>
                <div class="col-lg-6 footer_info_box flex-w">
                  <img
                    class="info_icon"
                    src="@/assets/images/houseInfo/bathrooms.png"
                    alt=""
                  />
                  <span class="T16px_Regular colorE0E0E0"
                    >{{ item.toilet }} Bathroom</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section card_box">
      <div class="container flex-wc">
        <img
          class="footer_img_logo"
          src="@/assets/images/home/footer_img.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
import { details, dictInfo, houseList } from "@/api/api.js";
export default {
  data() {
    return {
      info: {
        convenientArr: [],
        tenantArr: [],
        file:[]
      },
      list: [],
      locationList: [],
      convenientFacilitiesList: [],
      tenantConditionsList: [],
    };
  },
  created() {
    this.getDictList("locationNmae", "locationList");
    this.getDictList("convenient_facilities", "convenientFacilitiesList");
    this.getDictList("tenant_conditions", "tenantConditionsList");
    setTimeout(() => {
      this.getInfo(this.$route.query.id);
    }, 800);
  },
  methods: {
    /***
     * 
    */
    fileBtn(url){
      window.open(url)
    },
    /**
     * 根据想用的区域查询房子
     */
    getHouseList(region) {
      let param = {
        pageSize: 3,
        page: 1,
        maxPage: 1,
        region: region,
      };
      houseList(param).then((res) => {
        this.list = res.list;
      });
    },
    /**
     * 根据id显示当前名称\
     * @param {*} val 显示名称值
     * @param {*} name  查询参数名称
     */
    stringName(val, name) {
      let nameInfo = this[name].filter((item) => item.value == val);
      if (nameInfo.length != 0) {
        return nameInfo[0].label;
      }
      return "";
    },
    /**
     * 获取字典接口
     * @param {String} name 字典名称标题
     * @param {String} pramsName 赋值名称
     */
    getDictList(name, pramsName) {
      dictInfo({ name: name }).then((res) => {
        this[pramsName] = res.data;
      });
    },
    /**
     * 获取房产详情
     */
    getInfo(id) {
      details({ id: id }).then((res) => {
        res.data.convenientArr = [];
        res.data.tenantArr = [];
        if (res.data.convenient_facilities) {
          res.data.convenientArr = res.data.convenient_facilities.split(",");
        }
        if (res.data.tenant_conditions) {
          res.data.tenantArr = res.data.tenant_conditions.split(",");
        }

        this.info = res.data;
        this.getHouseList(res.data.region);
      });
    },
    /**
     *千元转换符
     */
    numberFormat(number) {
      let formatter = new Intl.NumberFormat("en-US"); // 使用德国的环境来格式化数字
      let formattedNumberDE = formatter.format(number);

      return formattedNumberDE;
    },
    /**
     * 切换显示轮播图方法
     * @param {*} val  切换轮播图方向
     */
    arrowClick(val) {
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    /**
     * 点击预览
     */
    viewBigPicture() {
      this.$refs.previewImg[0].showViewer = true;
    },
    linkGen(pageNum) {
      console.log(pageNum);
      this.currentPage = pageNum;
    },
    infoRouter(id) {
      this.$router.push({
        path: "houseInfo",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container_max {
  position: relative;
  height: 17.4688rem;
  .center_box {
    position: absolute;
    height: 10.5625rem;
    background-image: url("../../assets/images/home/home_bj.png");
    background-size: 10.625rem 10.5625rem;
    background-repeat: no-repeat;
    background-position: center;
    bottom: 1.4063rem;
    left: 0;
    right: 0;
    .text_personal,
    .zh_text_personal {
      letter-spacing: 0.1563rem;
      height: 0.75rem;
    }
    .zh_text_personal {
      margin-top: 0.5rem;
    }
  }
}

.about_my {
  // background: #292323;
  padding-top: 4.8438rem;
  padding-bottom: 0;

  .container {
    align-items: flex-end;
    position: relative;
  }
  .about_my_title {
    // height: 4.875rem;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .label_box {
    width: 4.7813rem;
    height: 1.3438rem;
    background: #a6b565;
    margin: 0.7813rem 0 0.625rem 0;
    .xian {
      width: 0.0313rem;
      height: 0.3438rem;
      background: #fff;
      margin: 0 0.625rem;
    }
  }
  .tips_box {
    margin-top: 0.4063rem;
    line-height: 1.0625rem;
    display: inline-block;
    margin-bottom: 0;
    padding-left: 0.625rem;
    .li {
      // list-style-type: none;
      height: 1.4063rem;
      line-height: 1.4063rem;
      position: relative;
      &::marker {
        font-size: 0.4375rem;
      }
    }
  }
  .y_info_btn {
    width: 7.3125rem;
    height: 1.9375rem;
    border: 0.0313rem solid #232020;
    background: transparent;
    margin-top: 0.5625rem;
  }

  .right_logo_box {
    width: 25.9063rem;
    height: 20.25rem ;
    margin-left: 1.0313rem;
    overflow: hidden;
    position: relative;
    .right_logo {
      width: 25.9063rem;
      height: 20.25rem ;
      overflow: hidden;
      background: #d9d9d9;
    }
    .Preview_box {
      position: absolute;
      z-index: 9;
      width: 4.8125rem;
      height: 1.75rem;
      border-radius: 0.0625rem;
      border: 0.0313rem solid #ffffff;
      background: transparent;
      left: 1.125rem;
      bottom: 0.9375rem;
      .icon {
        width: 0.9688rem;
        margin-right: 0.3125rem;
      }
    }
    .qie_box {
      position: absolute;
      right: 1.125rem;
      bottom: 0.9375rem;
      z-index: 9;
      .qie_btn {
        width: 1.75rem;
        height: 1.75rem;
        line-height: 1.75rem;
        border-radius: 0.0625rem;
        border: 0.0313rem solid #ffffff;
        background: transparent;
        margin: 0 0.3125rem;
      }
    }
  }
}

::v-deep .el-carousel__container {
  height: 100%;
}

.introduce {
  padding-top: 3.2813rem;
  .container {
    align-items: flex-start;
  }
  .phone {
    margin: 0.5625rem 0 1.875rem 0;
  }
  .remarks_text {
    width: 23.5625rem;
    line-height: 1.0625rem;
    letter-spacing: 0.0313rem;
  }
  .contacts_box {
    width: 23.5625rem;
    margin-top: 2.25rem;
    .left_box {
      .user_logo {
        width: 3.25rem;
        height: 3.25rem;
        margin-right: 1.875rem;
      }
      .one {
        letter-spacing: 0.0313rem;
        color: #333333;
      }
    }
  }
  .ideal_box {
    width: 23.5625rem;
    height: 7.375rem;
    margin-top: 3.25rem;
    background: #f0f1ee;
    border-radius: 0.0625rem;
    .ideal_name {
      color: #232020;
      letter-spacing: 0.125rem;
    }
    .ideal_tips {
      margin-top: 0.375rem;
      height: 1.1563rem;
      line-height: 0.7813rem;
      letter-spacing: 0.0625rem;
      margin-bottom: 0.5938rem;
      color: #232020;
      font-weight: 100;
    }

    .ideal_btn {
      width: 7.3438rem;
      height: 1.5625rem;
      background: #232020;
      border-radius: 0.0625rem;
      border: none;
    }
  }

  .max_ask_box {
    width: 8.9688rem;
    padding-top: 0.5625rem;

    .ask_box {
      .ask_name {
        color: #232020;
        letter-spacing: 0.0313rem;
        height: 1.1875rem;
        line-height: 1.1875rem;
      }

      .ask_item {
        height: 1.0625rem;
        padding: 0.75rem 0 0.3125rem 0;
        box-sizing: content-box;
        border-bottom: 0.0313rem solid #bdbdbd;
        .dui_img {
          width: 0.5625rem;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin-bottom: 2.1875rem;
        }
      }
    }
  }
}

.all_house {
  padding-top: 3.2188rem;
  padding-bottom: 4.6875rem;
  background: #292323;
  position: relative;
  .header_box {
    color: #e0e0e0;
    .header_title {
      color: #f2f2f2;
      height: 0.7813rem;
      line-height: 0.7813rem;
    }
  }
  .house_info_list {
    margin-top: 1.4375rem;
    .house_list {
      margin-top: 0.3125rem;
      .house_list_item {
        width: 13.0938rem;
        overflow: hidden;
        margin-right: 0.9375rem;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .img_box {
          position: relative;
          width: 13.0938rem;
          height: 8.0625rem;
          overflow: hidden;
          .house_logo {
            width: 13.0938rem;
            height: 8.0625rem;
            transition: all 0.5s;
          }
          .no_show_btn {
            position: absolute;
            left: -9.375rem;
            bottom: 0;
            width: 4.0625rem;
            height: 1.6875rem;
            background: #232020;
            border-radius: 0.0625rem;
            border: none;
            transition: all 0.5s;
          }
          .label_box {
            position: absolute;
            top: 0.4063rem;
            right: 0.4688rem;
            width: 4.0625rem;
            height: 1.125rem;
            background: #a6b565;
            .xian {
              width: 0.0313rem;
              height: 0.3438rem;
              background: #fff;
              margin: 0 0.4688rem;
            }
          }
        }
        .info_box {
          padding: 0.8125rem 1.2188rem 1.125rem 1.2188rem;
          border: 0.0313rem solid #d9d9d9;
          .name {
            width: 100%;
            height: 1.1875rem;
            line-height: 1.1875rem;
            margin: 0.25rem 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .footer_box {
          border: 0.0313rem solid #d9d9d9;
          height: 1.5625rem;
          border-top: none;
          .footer_info_box {
            padding-left: 1.25rem;
            position: relative;
            &:nth-child(1)::before {
              content: "";
              position: absolute;
              height: 0.7188rem;
              width: 0.0313rem;
              background: #e0e0e0;
              right: 0;
            }
            .info_icon {
              margin-right: 0.375rem;
            }
          }
        }

        &:hover {
          .no_show_btn {
            left: 0;
            transition: all 0.5s;
          }
          .house_logo {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }
      }
    }
  }
}

.card_box {
  background: #a6b565;
  padding: 1.875rem 0;
  border-radius: 0;
}
</style>
